import { useEffect, useState } from "react";
import { useFileUrl } from ".";

const useCsvReportPath = (filePath: string) => {
    const [downloadUrl, setDownloadUrl] = useState("");
    const [downloading, setDownloading] = useState(true);

    // TODO: This hook needs to wait for the URL to be generated instead of throwing an error, try subscription or create a new hook.
    const getFileUrl = useFileUrl();

    useEffect(() => {
        const subscription = getFileUrl(filePath).subscribe({
            next: (url) => {
                setDownloadUrl(url);
                setDownloading(false);
            },
        });

        return () => subscription.unsubscribe();
    }, [filePath, getFileUrl]);

    return { downloadUrl, downloading };
};

export default useCsvReportPath;
