import { groupBy, map, orderBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ICsvReportTimelineProps } from ".";
import { toDateString } from "../../utils/dates";
import CsvReport from "./CsvReport/CsvReport";
import styles from "./CsvReportTimeline.module.scss";

const CsvReportTimeline = ({ csvReports }: ICsvReportTimelineProps) => {
    const { t } = useTranslation();
    const groupedReports = useMemo(() => {
        return groupBy(
            orderBy(
                csvReports,
                [
                    (report) => report.date && report.date.split("T")[0],
                    (report) => report.date && report.date.split("T")[1],
                ],
                ["desc", "desc"],
            ),
            (report) => toDateString(new Date(report.date || 0)),
        );
    }, [csvReports]);

    return (
        <div>
            {csvReports && csvReports.length > 0
                ? map(groupedReports, (group, date) => (
                      <div key={date}>
                          <div className={styles.group}>{date}</div>
                          {group.map((report) => (
                              <CsvReport key={report.id} csvReport={report} />
                          ))}
                      </div>
                  ))
                : t("No CSV reports found")}
        </div>
    );
};

export default CsvReportTimeline;
