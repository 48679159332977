import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { KeyValueWidget } from "../../../components";
import { useAwaitingAllocationDeliveryPercentageByPriority } from "../../../utils/api/allocatedJobs";
import { encodeUrl } from "../../../utils/url";

const DeliveryPercentage = ({
    priorities,
    startDate,
    endDate,
}: IDeliveryPercentageProps) => {
    const { t } = useTranslation();

    const { value, loaded } = useAwaitingAllocationDeliveryPercentageByPriority(
        {
            jobPriorities: priorities,
            startDate,
            endDate,
        },
    );

    const summary = useMemo(() => {
        return value?.map((data) => ({
            key: t(data.priority),
            value: `${data.percentageComplete}%`,
        }));
    }, [t, value]);

    const url = useMemo(
        () =>
            encodeUrl("/jobs/awaiting-allocation", {
                activeTab: ["received"],
                slaDate: [
                    `>=${startDate.toISOString()}{AND}<${endDate.toISOString()}`,
                ],
                ...(!priorities.includes("All") && {
                    jobPriority: [priorities.map((j) => `=${j}`).join("{OR}")],
                }),
            }),
        [endDate, priorities, startDate],
    );

    return (
        <KeyValueWidget
            title={t("Delivery percentage for each priority")}
            summary={summary}
            url={url}
            tooltipText={t(
                "Shows the percentage of awaiting allocation jobs completed within SLA divided by all awaiting allocation jobs carried out grouped by SLA priority within the date filters selected.",
            )}
            loaded={loaded}
        />
    );
};

interface IDeliveryPercentageProps {
    startDate: Date;
    endDate: Date;
    priorities: string[];
}

export default DeliveryPercentage;
