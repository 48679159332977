import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { KeyValueWidget } from "../../../components";
import { useAwaitingAllocationJobCount } from "../../../utils/api/allocatedJobs";
import { encodeUrl } from "../../../utils/url";

const UtaProperty = ({ priorities, startDate, endDate }: IUtaPropertyProps) => {
    const { t } = useTranslation();

    const { value, loaded } = useAwaitingAllocationJobCount({
        jobPriorities: priorities,
        startDate,
        endDate,
        uta: true,
        jobStatus: ["Completed"],
    });

    const summary = useMemo(
        () => [
            {
                key: t("Total"),
                value: value?.count,
            },
        ],
        [t, value],
    );

    const url = useMemo(
        () =>
            encodeUrl("/jobs/awaiting-allocation", {
                activeTab: ["completed"],
                uta: ["=true"],
                slaDate: [
                    `>=${startDate.toISOString()}{AND}<${endDate.toISOString()}`,
                ],
                ...(!priorities.includes("All") && {
                    jobPriority: [priorities.map((j) => `=${j}`).join("{OR}")],
                }),
            }),
        [endDate, priorities, startDate],
    );

    return (
        <KeyValueWidget
            title={t("No access")}
            summary={summary}
            url={url}
            tooltipText={t(
                "Shows all allocated jobs leading on from a repair request that resulted in an unable to access within the date filters selected.",
            )}
            loaded={loaded}
        />
    );
};

interface IUtaPropertyProps {
    priorities: string[];
    startDate: Date;
    endDate: Date;
}

export default UtaProperty;
