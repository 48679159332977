import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { first, switchMap } from "rxjs";
import {
    Card,
    Link,
    Loading,
    PropertyCategoriesContext,
    UserPreferencesContext,
} from "../../../components";
import DashboardWidgetActiveFilters from "../../../components/DashboardWidgetActiveFilters";
import DashboardWidgetFilter, {
    IFilterGroup,
} from "../../../components/DashboardWidgetFilter";
import { useCssClasses } from "../../../hooks";
import { useFlags } from "../../../utils/api/misc";
import {
    IPhase,
    usePropertiesStatusGraph,
} from "../../../utils/api/properties";
import { getNow } from "../../../utils/dates";
import { encodeUrl, IUrlParameters } from "../../../utils/url";
import ChartActions from "../ChartComponents/ChartActions/ChartActions";
import ChartOverview from "../ChartComponents/ChartOverview";
import PropertyGraph from "../ChartComponents/PropertyGraph";
import styles from "./PropertyStatus.module.scss";

const PropertyStatus = () => {
    const { t } = useTranslation();
    const { preferences, updatePreferences } = useContext(
        UserPreferencesContext,
    );
    const { selectedCategories } = useContext(PropertyCategoriesContext);

    const { value: flags } = useFlags();

    const onlyGasOrAllSelected = useMemo(() => {
        return (
            selectedCategories.length === 0 ||
            selectedCategories.every((c) => c.id === 1)
        );
    }, [selectedCategories]);

    const onlyElectricSelected = useMemo(
        () =>
            selectedCategories.length === 1 &&
            selectedCategories.some((c) => c.id === 20),
        [selectedCategories],
    );

    const jobAllocatedStatus = useMemo(
        () => (onlyGasOrAllSelected && preferences.job_allocated_status) || "",
        [onlyGasOrAllSelected, preferences.job_allocated_status],
    );

    const cappedStatus = useMemo(
        () =>
            (onlyGasOrAllSelected && preferences.property_capped_status) || "",
        [onlyGasOrAllSelected, preferences.property_capped_status],
    );

    const propertyOccupiedStatus = useMemo(
        () => preferences.property_occupied_status || "",
        [preferences.property_occupied_status],
    );

    const propertyVisited = useMemo(
        () => (onlyElectricSelected && preferences.property_visits) || "",
        [onlyElectricSelected, preferences.property_visits],
    );

    const electricSupplyOn = useMemo(
        () => (onlyElectricSelected && preferences.electric_supply_on) || "",
        [onlyElectricSelected, preferences.electric_supply_on],
    );

    const selectedFlags = useMemo(
        () => preferences.property_status_flags_filter || [],
        [preferences.property_status_flags_filter],
    );

    const { value, loaded, loading, refresh } = usePropertiesStatusGraph(
        propertyOccupiedStatus,
        cappedStatus,
        jobAllocatedStatus,
        electricSupplyOn,
        propertyVisited,
        selectedFlags,
    );
    const [showGraph, setShowGraph] = useState(
        preferences.property_status_view === "graph",
    );

    const complianceRating = useMemo(() => {
        if (loaded) {
            const percentage =
                100 - (value.overdueProperties / value.totalProperties) * 100;

            if (!isNaN(percentage)) {
                return percentage.toFixed(2);
            }
        }
    }, [loaded, value]);

    const onSave = useCallback(
        (phases: IPhase[]) => {
            updatePreferences({
                ...preferences,
                property_status_phases: phases.map((phase) => ({
                    colour: phase.colour,
                    days_until_deadline: phase.phaseTimeFrame,
                    title: phase.title,
                })),
            })
                .pipe(switchMap(() => refresh().pipe(first())))
                .subscribe();
        },
        [preferences, refresh, updatePreferences],
    );

    const toggleGraphOverview = useCallback(
        (view: "graph" | "overview"): void => {
            updatePreferences({
                ...preferences,
                property_status_view: view,
            }).subscribe(() => setShowGraph(view === "graph"));
        },
        [preferences, updatePreferences],
    );

    // You can currently set duplicate phases, which leads to results with no dates.
    // Let's filter this out here
    const propertiesStatusPhases = useMemo(
        () => (value ? value.phases.filter((p) => p.dates.length > 0) : []),
        [value],
    );

    const getToday = () => {
        const today = getNow();
        today.setHours(0, 0, 0, 0);
        return today.toISOString();
    };

    const filters = useMemo(() => {
        const changeOccupiedStatus = (status: string) => {
            updatePreferences({
                ...preferences,
                property_occupied_status: status,
            }).subscribe();
        };

        const list: IFilterGroup[] = [
            {
                id: "occupied_status",
                title: t("Occupied status"),
                value: propertyOccupiedStatus,
                changeValue: changeOccupiedStatus,
                allowEmptyValue: true,
                options: [
                    { label: t("Occupied"), value: "true" },
                    { label: t("Void"), value: "false" },
                ],
            },
        ];

        if (onlyGasOrAllSelected) {
            const changeJobAllocatedStatus = (status: string) => {
                updatePreferences({
                    ...preferences,
                    job_allocated_status: status,
                }).subscribe();
            };

            const changeCappedStatus = (status: string) => {
                updatePreferences({
                    ...preferences,
                    property_capped_status: status,
                }).subscribe();
            };

            list.unshift(
                {
                    id: "capped_status",
                    title: t("Capped status"),
                    value: cappedStatus,
                    changeValue: changeCappedStatus,
                    allowEmptyValue: true,
                    options: [
                        { label: t("Capped"), value: "true" },
                        { label: t("Not capped"), value: "false" },
                    ],
                },
                {
                    id: "appointment_status",
                    title: t("Appointment status"),
                    value: jobAllocatedStatus,
                    changeValue: changeJobAllocatedStatus,
                    allowEmptyValue: true,
                    options: [
                        { label: t("Job allocated"), value: "Yes" },
                        { label: t("No job allocated"), value: "No" },
                    ],
                },
            );
        } else if (onlyElectricSelected) {
            const changeElectricSupplyOn = (updatedSupplyOn: string) => {
                updatePreferences({
                    ...preferences,
                    electric_supply_on: updatedSupplyOn,
                }).subscribe();
            };

            const changePropertyVisits = (updatedPropertyVisits: string) => {
                updatePreferences({
                    ...preferences,
                    property_visits: updatedPropertyVisits,
                }).subscribe();
            };

            list.unshift(
                {
                    id: "property_visits",
                    title: t("Property visits"),
                    value: propertyVisited,
                    changeValue: changePropertyVisits,
                    allowEmptyValue: true,
                    options: [
                        { label: t("Visited properties"), value: "Yes" },
                        { label: t("Unvisited properties"), value: "No" },
                    ],
                },
                {
                    id: "supply_on",
                    title: t("Supply On"),
                    value: electricSupplyOn,
                    changeValue: changeElectricSupplyOn,
                    allowEmptyValue: true,
                    options: [
                        { label: t("Supply on"), value: "Yes" },
                        { label: t("Supply off"), value: "No" },
                    ],
                },
            );
        }

        if (flags) {
            const changeSelectedFlags = (updatedFlag: string) => {
                const newFlags = [...selectedFlags];

                const index = selectedFlags.indexOf(updatedFlag);

                if (index > -1) {
                    newFlags.splice(index, 1);
                } else {
                    newFlags.push(updatedFlag);
                }

                updatePreferences({
                    ...preferences,
                    property_status_flags_filter: newFlags,
                }).subscribe();
            };

            list.push({
                id: "flags",
                title: t("Flags"),
                value: selectedFlags.map((f) => f.toString()),
                changeValue: changeSelectedFlags,
                allowEmptyValue: false,
                options: [...new Set(flags.map((f) => f.name))].map((f) => ({
                    label: f,
                    value: f,
                })),
            });
        }

        return list;
    }, [
        cappedStatus,
        electricSupplyOn,
        flags,
        jobAllocatedStatus,
        onlyElectricSelected,
        onlyGasOrAllSelected,
        preferences,
        propertyOccupiedStatus,
        propertyVisited,
        selectedFlags,
        t,
        updatePreferences,
    ]);

    const labelCssClasses = useCssClasses(
        styles.label,
        selectedCategories.length !== 1 ? "" : styles.large,
    );

    const valueCssClasses = useCssClasses(
        styles.value,
        selectedCategories.length !== 1 ? "" : styles.large,
    );

    const urlParams = useMemo(() => {
        const params: IUrlParameters = {
            nextServiceDueDate: [`<${getToday()}`],
            ...(propertyOccupiedStatus !== "" && {
                occupiedStatus: [
                    `=${
                        propertyOccupiedStatus === "true" ? "Occupied" : "Void"
                    }`,
                ],
            }),
            ...(cappedStatus !== "" && {
                cappedStatus: [
                    `=${cappedStatus === "true" ? "Capped" : "Not Capped"}`,
                ],
            }),
            ...(jobAllocatedStatus !== "" && {
                serviceAllocatedStatus: [`=${jobAllocatedStatus}`],
            }),
            ...(propertyVisited !== "" && {
                propertyVisited: [`=${propertyVisited}`],
            }),
            ...(electricSupplyOn !== "" && {
                electricSupplyOn: [`=${electricSupplyOn}`],
            }),
            ...(selectedFlags.length > 0 && {
                flags: [`${selectedFlags.map((f) => `=${f}`).join("{OR}")}`],
            }),
        };

        return params;
    }, [
        cappedStatus,
        electricSupplyOn,
        jobAllocatedStatus,
        propertyOccupiedStatus,
        propertyVisited,
        selectedFlags,
    ]);

    return (
        <Card
            title={t("Property status")}
            fullHeight={true}
            testId="PropertyServices_Widget"
            actions={
                <ChartActions
                    phases={(value && value.phases) || []}
                    savePhaseLimits={onSave}
                    showGraph={showGraph}
                    toggleGraphOverview={toggleGraphOverview}
                    phaseTimeframe="Days Until Deadline"
                >
                    <DashboardWidgetFilter filters={filters} />
                </ChartActions>
            }
        >
            {!loading ? (
                <div
                    className={styles.container}
                    data-test-id="PropertyServicesGraph_Container"
                >
                    <div className={styles.leftColumn}>
                        <div className={valueCssClasses}>
                            <Link
                                url={encodeUrl(
                                    "/management/service-dates",
                                    urlParams,
                                )}
                                color="red"
                                testId="OverdueServices_Total_A"
                            >
                                {(value && value.overdueServices) || 0}
                            </Link>
                        </div>
                        <p className={labelCssClasses}>
                            {t("Overdue services")}
                        </p>
                        {selectedCategories.length !== 1 && (
                            <>
                                <div className={valueCssClasses}>
                                    <Link
                                        url={encodeUrl(
                                            "/management/properties",
                                            urlParams,
                                        )}
                                        color="red"
                                        testId="OverdueProperties_Total_A"
                                    >
                                        {(value && value.overdueProperties) ||
                                            0}
                                    </Link>
                                </div>
                                <p className={labelCssClasses}>
                                    {t("Overdue properties")}
                                </p>
                            </>
                        )}
                        {complianceRating && (
                            <>
                                <div
                                    className={`${valueCssClasses} ${styles.green}`}
                                    data-test-id="ComplianceRating_Percentage_Div"
                                >
                                    {complianceRating}%
                                </div>
                                <p className={labelCssClasses}>
                                    {t("Compliance rating")}
                                </p>
                            </>
                        )}
                    </div>

                    <div className={styles.rightColumn}>
                        {showGraph ? (
                            <PropertyGraph
                                phases={propertiesStatusPhases}
                                maxHeight={200}
                                dateFilterField="nextServiceDueDate"
                                targetUrl="/management/service-dates"
                                targetUrlParams={urlParams}
                                phaseTimeFrameMultiplier={1}
                                areDatesInPast={false}
                            />
                        ) : (
                            <ChartOverview
                                phases={propertiesStatusPhases}
                                dateFilterField="nextServiceDueDate"
                                targetUrl="/management/service-dates"
                                targetUrlParams={urlParams}
                                phaseTimeFrameMultiplier={1}
                                areDatesInPast={false}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <Loading />
            )}

            <DashboardWidgetActiveFilters filters={filters} />
        </Card>
    );
};

export default PropertyStatus;
