import { useTranslation } from "react-i18next";
import { ICsvReportProps } from ".";
import { Link, Loading } from "../..";
import { useCsvReportPath } from "../../../utils/api/misc";
import { toTimeString } from "../../../utils/dates";
import styles from "./CsvReport.module.scss";

const CsvReport = ({ csvReport }: ICsvReportProps) => {
    const { downloadUrl, downloading } = useCsvReportPath(csvReport.filePath);
    const { t } = useTranslation();

    const statusStyle =
        csvReport.reportStatus === "Failed"
            ? `${styles.reportDetailsAlignedLeft} ${styles.failed}`
            : styles.reportDetailsAlignedLeft;

    return (
        <div className={styles.container}>
            <div className={styles.reportHeader}>
                <div className={styles.reportTime}>
                    {toTimeString(new Date(csvReport.date))}
                </div>

                <div className={styles.reportSeparator} />

                <div className={styles.reportDetails}>
                    <div className={styles.reportDetailsAlignedLeft}>
                        <div className={styles.reportDetailsProperty}>
                            <strong>{csvReport.reportType}</strong>
                        </div>

                        <div className={styles.timelineSeparator} />

                        <div className={statusStyle}>{`${t("Report status:")} ${
                            csvReport.reportStatus
                        }`}</div>

                        <div className={styles.timelineSeparator} />

                        <div className={styles.reportDetailsAlignedRight}>
                            {downloading
                                ? csvReport.reportStatus !== "Failed" && (
                                      <div>
                                          <Loading small={true} />
                                      </div>
                                  )
                                : csvReport.reportStatus === "Complete" && (
                                      <div>
                                          <Link url={downloadUrl}>
                                              {t("Download")}
                                          </Link>
                                      </div>
                                  )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CsvReport;
