import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Card,
    EditablePill,
    Icon,
    Link,
    Loading,
    Modal,
    Portal,
    PropertyCategoriesContext,
    PropertyFlagEditor,
    Table,
    UserContext,
} from "../../components";
import AddNote from "../../components/AddNote";
import AllocateJobSimpleModal from "../../components/AllocateJobSimpleModal";
import ModalBody from "../../components/Modal/ModalBody";
import { IBulkAction, ITableColumn } from "../../components/Table";
import Toast from "../../components/Toast";
import config from "../../config";
import { useToast, useToggle } from "../../hooks";
import useDownloadCsv from "../../hooks/useDownloadCsv";
import useQueryString from "../../hooks/useQueryString";
import { useUpdatePropertyFlags } from "../../hooks/useUpdatePropertyFlags";
import { useAllContractors } from "../../utils/api/landlords";
import { useFlags } from "../../utils/api/misc";
import {
    IPropertyCategory,
    IPropertyFlag,
    ISimpleProperty,
    useProperties,
} from "../../utils/api/properties";
import useAddNote from "../../utils/api/properties/useAddNote";
import { clearCache } from "../../utils/cache";
import { getDifferenceInDays, getToday, toDateString } from "../../utils/dates";
import AssignContractorsModal, {
    IAssignContractorsComplianceType,
} from "./AssignContractorsModal";
import TableActions from "./TableActions";
import TableFlagGroup from "./TableFlagGroup/TableFlagGroup";

const Properties = () => {
    const { t } = useTranslation();

    const properties = useProperties({
        sortProperty: "addressString",
        sortDirection: "asc",
    });
    const { records, refresh, filters, loaded, search } = properties;

    const { records: contractors } = useAllContractors();

    const { value: flags, updateValue: updateFlags } = useFlags();
    const { addNote } = useAddNote();

    const toast = useToast();
    const [bulkPropertyIds, setBulkPropertyIds] = useState<number[]>([]);
    const [bulkPropertyCategories, setBulkPropertyCategories] = useState<
        IAssignContractorsComplianceType[]
    >([]);

    const [selectedProperty, setSelectedProperty] =
        useState<ISimpleProperty | null>(null);
    const [landlordId, setLandlordId] = useState<number | null>(null);
    const [flagsIds, setFlagsIds] = useState<number[]>([]);

    const {
        show: assignContractorsModalShow,
        visible: assignContractorsModalVisible,
        hide: assignContractorsModalHide,
    } = useToggle();

    const {
        show: editFlagsModalShow,
        visible: editFlagsModalVisible,
        hide: editFlagsModalHide,
    } = useToggle();

    const {
        show: assignFlagsModalShow,
        visible: assignFlagsModalVisible,
        hide: assignFlagsModalHide,
    } = useToggle();

    const {
        show: unableEditFlagsModalShow,
        visible: unableEditFlagsModalVisible,
        hide: unableEditFlagsModalHide,
    } = useToggle();

    const {
        show: allocateJobModalShow,
        visible: allocateJobModalVisible,
        hide: allocateJobModalHide,
    } = useToggle();

    const {
        show: addNoteModalShow,
        visible: addNoteModalVisible,
        hide: addNoteModalHide,
    } = useToggle();

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);

    const openAssignContractorModal = useCallback(
        (ids: number[]) => {
            const selectedProperties = records.filter((p) =>
                ids.includes(p.id),
            );

            const propertyCategories: {
                [key: string]: IAssignContractorsComplianceType;
            } = {};

            for (const property of selectedProperties) {
                for (const category of property.categories) {
                    if (!propertyCategories[category.propertyCategoryId]) {
                        propertyCategories[category.propertyCategoryId] = {
                            id: category.propertyCategoryId,
                            displayName: category.displayName,
                            colour: category.colour,
                        };
                    }
                }
            }

            setBulkPropertyCategories(Object.values(propertyCategories));
            setBulkPropertyIds(ids);
            assignContractorsModalShow();
        },
        [assignContractorsModalShow, records],
    );

    const openEditFlagsModal = useCallback(
        (id: number) => {
            setBulkPropertyIds([id]);

            const property = records.find((prop) => prop.id === id);

            if (property) {
                const flagIds = property.flags.map((flag) => flag.id);
                setFlagsIds(flagIds);

                setLandlordId(property.landlord.id);
                editFlagsModalShow();
            }
        },
        [editFlagsModalShow, records],
    );

    const openAssignFlagsModal = useCallback(
        (ids: number[]) => {
            setBulkPropertyIds(ids);

            const property = records.find((prop) => prop.id === ids[0]);

            if (property) {
                setFlagsIds([]);

                setLandlordId(property.landlord.id);
                assignFlagsModalShow();
            }
        },
        [assignFlagsModalShow, records],
    );

    const addNoteModalSave = useCallback(
        (propertyId: number, note: string) => {
            addNote({ note, propertyId }).subscribe(() => {
                clearCache();
                refresh();
                addNoteModalHide();
            });
        },
        [addNote, addNoteModalHide, refresh],
    );

    const openAddNoteModal = useCallback(
        (id: number) => {
            const property = records.find((prop) => prop.id === id);

            if (property) {
                setSelectedProperty(property);
                addNoteModalShow();
            }
        },
        [addNoteModalShow, records],
    );

    const openAllocateJobModal = useCallback(
        (id: number) => {
            const property = records.find((prop) => prop.id === id);

            if (property) {
                setSelectedProperty(property);
                allocateJobModalShow();
            }
        },
        [allocateJobModalShow, records],
    );

    const { updatePropertyFlags: editPropertyFlags } = useUpdatePropertyFlags(
        bulkPropertyIds,
        true,
        refresh,
        editFlagsModalHide,
    );

    const { updatePropertyFlags: assignPropertyFlags } = useUpdatePropertyFlags(
        bulkPropertyIds,
        false,
        refresh,
        assignFlagsModalHide,
    );

    const { getQueryString } = useQueryString(search);
    const { handleDownloadClick } = useDownloadCsv({
        exportDataUrl: `${config.propertiesApiUrl}/export`,
        filters,
        filterColumns: (vc) => vc.key !== "actions",
        search: getQueryString(),
        showToast: toast.show,
        getUrl: false,
    });

    const columns = useMemo(() => {
        const renderAddress = (value: string, row: ISimpleProperty) => (
            <Link url={`/management/properties/${row.id}`}>{value}</Link>
        );

        const renderLatestNote = (value: unknown, row: ISimpleProperty) => {
            return row.latestPropertyNote ? (
                <span title={row.latestPropertyNote.note}>
                    {toDateString(new Date(row.latestPropertyNote.createdAt))}
                    {": "}
                    {row.latestPropertyNote.note.substring(0, 100)}
                </span>
            ) : (
                ""
            );
        };

        const renderFlags = (value: IPropertyFlag[]) => {
            return <TableFlagGroup flags={value} />;
        };

        const renderAccessProcedure = (
            value: unknown,
            row: ISimpleProperty,
        ) => {
            return (
                row.currentAttemptsCount >= 3 && (
                    <Icon
                        icon="check"
                        ariaLabel={t("Access Procedure Followed")}
                    />
                )
            );
        };

        const renderLastServiceDate = (
            value: unknown,
            row: ISimpleProperty,
        ) => {
            if (row.lastServiceDate) {
                return <div>{toDateString(new Date(row.lastServiceDate))}</div>;
            } else {
                return t("Awaiting First Safety Check");
            }
        };

        const renderPropertyStatus = (value: unknown, row: ISimpleProperty) => {
            if (row.nextServiceDueDate) {
                const diff = getDifferenceInDays(
                    getToday(),
                    new Date(row.nextServiceDueDate),
                );

                if (diff > 31) {
                    return t("Serviced");
                } else if (diff > 7) {
                    return t("Due in 1 month");
                } else if (diff >= 0) {
                    return t("Due in 1 week");
                } else {
                    return t("Overdue");
                }
            }
        };

        const renderContractor = (value: unknown, row: ISimpleProperty) => {
            if (selectedCategories.length !== 1) {
                if (
                    row.categories.filter((c) => c.contractors.length > 0)
                        .length > 1
                ) {
                    return t("Multiple");
                } else {
                    return row.categories
                        .filter((c) => c.contractors.length > 0)
                        .flatMap((c) => c.contractors.map((ct) => ct.name))
                        .join(", ");
                }
            } else {
                return row.categories
                    .filter((category) =>
                        selectedCategories.some(
                            (c) => c.id === category.propertyCategoryId,
                        ),
                    )
                    .flatMap((category) =>
                        category.contractors.map(
                            (contractor) => contractor.name,
                        ),
                    )
                    .join(", ");
            }
        };

        const renderActions = (value: unknown, row: ISimpleProperty) => {
            const assignContractorModalOpen = () => {
                openAssignContractorModal([row.id]);
            };

            const editPropertyFlagsModalOpen = () => {
                openEditFlagsModal(row.id);
            };

            const allocateJobModalOpen = () => {
                openAllocateJobModal(row.id);
            };

            const addNoteModalOpen = () => {
                openAddNoteModal(row.id);
            };

            return (
                <TableActions
                    assignContractorModalOpen={assignContractorModalOpen}
                    editPropertyFlagsModalOpen={editPropertyFlagsModalOpen}
                    allocateJobModalOpen={allocateJobModalOpen}
                    addNoteModalOpen={addNoteModalOpen}
                />
            );
        };

        const col: { [key: string]: ITableColumn<ISimpleProperty> } = {
            addressString: {
                title: t("Address"),
                filterable: false,
                canBeToggledByUser: false,
                render: renderAddress,
            },
            "landlord.name": {
                title: t("Landlord"),
            },
            propertyType: {
                title: t("Property Type"),
            },
            totalUnsafeAssets: {
                title: t("Total unsafe assets"),
                type: "number",
            },
            landlordUnsafeAssets: {
                title: t("Landlord's unsafe assets"),
                type: "number",
            },
            tenantUnsafeAssets: {
                title: t("Tenant's unsafe assets"),
                type: "number",
            },
            lastServiceDate: {
                title: t("Last Job Date"),
                type: "date",
                render: renderLastServiceDate,
            },
            lastLgsrJobDate: {
                title: t("Last LGSR Job Date"),
                type: "date",
            },
            "letterState.status": {
                title: t("Letter Status"),
            },
            statusOverdue: {
                title: t("Status Overdue"),
                sortable: false,
            },
            nextServiceDueDate: {
                title: t("Next Service Due Date"),
                type: "date",
            },
            lastMaintenanceDate: {
                title: t("Last Maintenance Date"),
                type: "date",
            },
            nextMaintenanceDate: {
                title: t("Next Maintenance Date"),
                type: "date",
            },
            latestPropertyNote: {
                title: t("Most Recent Note"),
                filterable: false,
                sortable: false,
                render: renderLatestNote,
            },
            propertyStatus: {
                title: t("Property Status"),
                render: renderPropertyStatus,
                sortable: false,
            },
            "contractor.name": {
                title: t("Contractor"),
                sortable: false,
                filterable: selectedCategories.length === 1,
                render: renderContractor,
            },
            currentAttemptsCount: {
                title: t("No Access Attempt"),
                type: "number",
            },
            lastAttemptDate: {
                title: t("Latest Attempt"),
                type: "date",
            },
            uprn: {
                title: t("UPRN"),
                filterable: false,
            },
            flags: {
                title: t("Flags"),
                sortable: false,
                render: renderFlags,
            },
            categories: {
                title: t("Property Categories"),
                sortable: false,
                render: (value: IPropertyCategory[]) => {
                    return value.map((c) => (
                        <EditablePill
                            key={c.id}
                            name={c.displayName}
                            colour={c.colour}
                        />
                    ));
                },
            },
            "tag.installedDate": {
                title: t("First Tag Installed Date"),
                type: "date",
            },

            tagCount: {
                title: t("Number of Tags"),
                type: "number",
                render: (value, row) => {
                    return row.tags ? row.tags.length : 0;
                },
            },
            accessProcedure: {
                title: t("Access Procedure Followed"),
                render: renderAccessProcedure,
            },
            "tag.engineer.name": {
                title: t("Engineer"),
            },
            voidDate: {
                title: t("Void Date"),
                type: "date",
            },
            cappedDate: {
                title: t("Capped Date"),
                type: "date",
            },
            occupiedStatus: {
                title: t("Occupied"),
                sortable: false,
                render: (value: boolean) => (value ? t("Occupied") : t("Void")),
            },
            cappedStatus: {
                title: t("Capped"),
                sortable: false,
                render: (value: boolean) =>
                    value ? t("Capped") : t("Not Capped"),
            },
            gasMeterLocation: {
                title: t("Gas Meter Location"),
            },
            nextAppointmentDate: {
                title: t("Next Appointment"),
                type: "date",
            },
            serviceAllocatedStatus: {
                title: t("Gas service allocated"),
                sortable: false,
                render: (value) => {
                    return value ? "Yes" : "No";
                },
            },
            createdAt: {
                title: t("Added Date"),
                type: "date",
            },
            actions: {
                title: t("Actions"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderActions,
            },
        };
        return col;
    }, [
        t,
        selectedCategories,
        openAssignContractorModal,
        openEditFlagsModal,
        openAllocateJobModal,
        openAddNoteModal,
    ]);

    const bulkActions = useMemo<IBulkAction[]>(
        () => [
            {
                value: "contractors",
                label: t("Assign Contractors"),
                onSubmit: openAssignContractorModal,
            },
            {
                value: "flags",
                label: t("Assign Flags"),
                onSubmit:
                    activeUserParentsIds.length === 1
                        ? openAssignFlagsModal
                        : unableEditFlagsModalShow,
            },
        ],
        [
            openAssignContractorModal,
            openAssignFlagsModal,
            t,
            unableEditFlagsModalShow,
            activeUserParentsIds,
        ],
    );

    const propertyCategories = useMemo(
        () =>
            selectedProperty !== null
                ? selectedProperty.categories.map((c) => ({
                      id: c.propertyCategoryId,
                      displayName: c.displayName,
                  }))
                : [],
        [selectedProperty],
    );

    return (
        <>
            <Card title={t("Properties")}>
                {loaded ? (
                    <>
                        {selectedCategories.length > 1 && (
                            <div style={{ marginBottom: "10px" }}>
                                <Alert type="info">
                                    {t(
                                        "You are viewing the earliest service date and the highest no access count for all compliance types registered to a property. Select individual compliance types to view specific service dates and no access counts.",
                                    )}
                                </Alert>
                            </div>
                        )}

                        <Table
                            preferences="properties-table"
                            columns={columns}
                            bulkActions={bulkActions}
                            {...properties}
                            alternateCsvFunction={handleDownloadClick}
                            hideChildComponent={toast.visible}
                        />
                    </>
                ) : (
                    <Loading />
                )}

                {assignContractorsModalVisible && (
                    <AssignContractorsModal
                        propertyIds={bulkPropertyIds}
                        complianceTypes={bulkPropertyCategories}
                        contractors={contractors}
                        hide={assignContractorsModalHide}
                    />
                )}

                {editFlagsModalVisible && landlordId && (
                    <Portal>
                        <Modal
                            title={t("Edit flags")}
                            hide={editFlagsModalHide}
                        >
                            <ModalBody>
                                <PropertyFlagEditor
                                    landlordId={landlordId}
                                    flags={flags}
                                    selectedFlagsIds={flagsIds}
                                    onSave={editPropertyFlags}
                                    onCancel={editFlagsModalHide}
                                    updateValue={updateFlags}
                                />
                            </ModalBody>
                        </Modal>
                    </Portal>
                )}

                {assignFlagsModalVisible && landlordId && (
                    <Portal>
                        <Modal
                            title={t("Assign flags")}
                            hide={assignFlagsModalHide}
                        >
                            <ModalBody>
                                <PropertyFlagEditor
                                    landlordId={landlordId}
                                    flags={flags}
                                    selectedFlagsIds={flagsIds}
                                    onSave={assignPropertyFlags}
                                    onCancel={assignFlagsModalHide}
                                    updateValue={updateFlags}
                                />
                            </ModalBody>
                        </Modal>
                    </Portal>
                )}

                {addNoteModalVisible && selectedProperty && (
                    <AddNote
                        propertyId={selectedProperty.id}
                        onCancel={addNoteModalHide}
                        onSave={addNoteModalSave}
                    />
                )}

                {unableEditFlagsModalVisible && (
                    <Portal>
                        <Modal
                            title={t("Can't edit flags")}
                            hide={unableEditFlagsModalHide}
                        >
                            <ModalBody>
                                <p>
                                    {t(
                                        "You can't edit a flag while having more than one user parent selected",
                                    )}
                                </p>
                            </ModalBody>
                        </Modal>
                    </Portal>
                )}

                {allocateJobModalVisible && selectedProperty && (
                    <AllocateJobSimpleModal
                        propertyId={selectedProperty.id}
                        propertyCategories={propertyCategories}
                        addressString={selectedProperty.addressString}
                        hide={allocateJobModalHide}
                    />
                )}
            </Card>

            {toast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    );
};

export default Properties;
