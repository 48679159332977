import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    EditablePill,
    Link,
    Loading,
    Modal,
    Portal,
    PropertyCategoriesContext,
    PropertyFlagEditor,
    Table,
} from "../../components";
import AllocateJobSimpleModal from "../../components/AllocateJobSimpleModal";
import ModalBody from "../../components/Modal/ModalBody";
import { ITableColumn } from "../../components/Table";
import Toast from "../../components/Toast";
import config from "../../config";
import { useToast, useToggle } from "../../hooks";
import useDownloadCsv from "../../hooks/useDownloadCsv";
import useQueryString from "../../hooks/useQueryString";
import { useUpdatePropertyFlags } from "../../hooks/useUpdatePropertyFlags";
import { IContractor, useAllContractors } from "../../utils/api/landlords";
import { useFlags } from "../../utils/api/misc";
import {
    IPropertyCategory,
    IPropertyFlag,
    IPropertyServiceDates,
    usePropertyServiceDates,
} from "../../utils/api/properties";
import { getDifferenceInDays, getToday } from "../../utils/dates";
import AssignContractorsModal, {
    IAssignContractorsComplianceType,
} from "../Properties/AssignContractorsModal";
import TableActions from "../Properties/TableActions";
import TableFlagGroup from "../Properties/TableFlagGroup/TableFlagGroup";

const PropertyServiceDates = () => {
    const { t } = useTranslation();

    const properties = usePropertyServiceDates({
        sortProperty: "addressString",
        sortDirection: "asc",
    });
    const { records, refresh, filters, loaded, search } = properties;

    const toast = useToast();
    const { records: contractors } = useAllContractors();
    const { value: flags, updateValue: updateFlags } = useFlags();

    const [bulkPropertyIds, setBulkPropertyIds] = useState<number[]>([]);
    const [bulkPropertyCategories, setBulkPropertyCategories] = useState<
        IAssignContractorsComplianceType[]
    >([]);

    const [selectedProperty, setSelectedProperty] =
        useState<IPropertyServiceDates | null>(null);
    const [landlordId, setLandlordId] = useState<number | null>(null);
    const [flagsIds, setFlagsIds] = useState<number[]>([]);

    const {
        show: assignContractorsModalShow,
        visible: assignContractorsModalVisible,
        hide: assignContractorsModalHide,
    } = useToggle();

    const {
        show: editFlagsModalShow,
        visible: editFlagsModalVisible,
        hide: editFlagsModalHide,
    } = useToggle();

    const { visible: assignFlagsModalVisible, hide: assignFlagsModalHide } =
        useToggle();

    const {
        visible: unableEditFlagsModalVisible,
        hide: unableEditFlagsModalHide,
    } = useToggle();

    const {
        show: allocateJobModalShow,
        visible: allocateJobModalVisible,
        hide: allocateJobModalHide,
    } = useToggle();

    const openAssignContractorModal = useCallback(
        (ids: number[]) => {
            const selectedProperties = records.filter((p) =>
                ids.includes(p.id),
            );

            const propertyCategories: {
                [key: string]: IAssignContractorsComplianceType;
            } = {};

            for (const property of selectedProperties) {
                if (!propertyCategories[property.category.id]) {
                    propertyCategories[property.category.id] = {
                        id: property.category.id,
                        displayName: property.category.displayName,
                        colour: property.category.colour,
                    };
                }
            }

            setBulkPropertyCategories(Object.values(propertyCategories));
            setBulkPropertyIds(selectedProperties.map((p) => p.propertyId));
            assignContractorsModalShow();
        },
        [assignContractorsModalShow, records],
    );

    const openEditFlagsModal = useCallback(
        (id: number) => {
            const property = records.find((p) => p.id === id);

            if (property) {
                setBulkPropertyIds([property.propertyId]);

                const flagIds = property.flags.map((flag) => flag.id);
                setFlagsIds(flagIds);

                setLandlordId(property.landlord.id);
                editFlagsModalShow();
            }
        },
        [editFlagsModalShow, records],
    );

    const openAllocateJobModal = useCallback(
        (id: number) => {
            const property = records.find((p) => p.id === id);

            if (property) {
                setSelectedProperty(property);
                allocateJobModalShow();
            }
        },
        [allocateJobModalShow, records],
    );

    const { updatePropertyFlags: editPropertyFlags } = useUpdatePropertyFlags(
        bulkPropertyIds,
        true,
        refresh,
        editFlagsModalHide,
    );

    const { updatePropertyFlags: assignPropertyFlags } = useUpdatePropertyFlags(
        bulkPropertyIds,
        false,
        refresh,
        assignFlagsModalHide,
    );

    const { getQueryString } = useQueryString(search);

    const { handleDownloadClick } = useDownloadCsv({
        exportDataUrl: `${config.propertiesApiUrl}/servicedates/export`,
        filters: filters,
        filterColumns: (vc) => vc.key !== "actions",
        search: getQueryString(),
        showToast: toast.show,
        getUrl: false,
    });

    const { selectedCategories } = useContext(PropertyCategoriesContext);

    const columns = useMemo(() => {
        const renderAddress = (value: string, row: IPropertyServiceDates) => (
            <Link url={`/management/properties/${row.propertyId}`}>
                {value}
            </Link>
        );

        const renderPropertyStatus = (
            value: unknown,
            row: IPropertyServiceDates,
        ) => {
            if (row.nextServiceDueDate) {
                const diff = getDifferenceInDays(
                    getToday(),
                    new Date(row.nextServiceDueDate),
                );

                if (diff > 31) {
                    return t("Serviced");
                } else if (diff > 7) {
                    return t("Due in 1 month");
                } else if (diff >= 0) {
                    return t("Due in 1 week");
                } else {
                    return t("Overdue");
                }
            }
        };

        const renderFlags = (value: IPropertyFlag[]) => {
            return <TableFlagGroup flags={value} />;
        };

        const renderCategory = (value: IPropertyCategory) => {
            return (
                <EditablePill name={value.displayName} colour={value.colour} />
            );
        };

        const renderActions = (value: unknown, row: IPropertyServiceDates) => {
            const assignContractorModalOpen = () => {
                openAssignContractorModal([row.id]);
            };

            const editPropertyFlagsModalOpen = () => {
                openEditFlagsModal(row.id);
            };

            const allocateJobModalOpen = () => {
                openAllocateJobModal(row.id);
            };

            return (
                <TableActions
                    assignContractorModalOpen={assignContractorModalOpen}
                    editPropertyFlagsModalOpen={editPropertyFlagsModalOpen}
                    allocateJobModalOpen={allocateJobModalOpen}
                />
            );
        };

        const col: { [key: string]: ITableColumn<IPropertyServiceDates> } = {
            addressString: {
                title: t("Address"),
                filterable: false,
                canBeToggledByUser: false,
                render: renderAddress,
            },
            "landlord.name": {
                title: t("Landlord"),
            },
            lastLgsrJobDate: {
                title: t("Last LGSR Job Date"),
                type: "date",
            },
            nextServiceDueDate: {
                title: t("Next Service Due Date"),
                type: "date",
            },
            lastMaintenanceDate: {
                title: t("Last Maintenance Date"),
                type: "date",
            },
            propertyStatus: {
                title: t("Property Status"),
                render: renderPropertyStatus,
                sortable: false,
            },
            currentAttemptsCount: {
                title: t("No Access Attempt"),
                type: "number",
            },
            lastAttemptDate: {
                title: t("Latest Attempt"),
                type: "date",
            },
            contractors: {
                title: t("Contractor"),
                filterable: true,
                render: (value: IContractor[]) =>
                    value.map((contractor) => contractor.name).join(", "),
            },
            uprn: {
                title: t("UPRN"),
                filterable: false,
            },
            flags: {
                title: t("Flags"),
                sortable: false,
                render: renderFlags,
            },
            category: {
                title: t("Property Categories"),
                sortable: false,
                render: renderCategory,
            },
            occupiedStatus: {
                title: t("Occupied"),
                sortable: false,
                render: (value: boolean) => (value ? t("Occupied") : t("Void")),
            },
            cappedStatus: {
                title: t("Capped"),
                sortable: false,
                render: (value: boolean) =>
                    value ? t("Capped") : t("Not Capped"),
            },
            nextAppointmentDate: {
                title: t("Next Appointment"),
                type: "date",
            },
            serviceAllocatedStatus: {
                title: t("Gas service allocated"),
                sortable: false,
                render: (value: boolean) => {
                    return value ? t("Yes") : t("No");
                },
            },
            propertyVisited: {
                title: t("Property visited"),
                sortable: false,
                hidden:
                    selectedCategories.length > 0 &&
                    !selectedCategories.some((c) => c.id === 20),
                render: (value: boolean) => {
                    return value ? t("Yes") : t("No");
                },
            },
            electricSupplyOn: {
                title: t("Electric supply on"),
                sortable: false,
                hidden:
                    selectedCategories.length > 0 &&
                    !selectedCategories.some((c) => c.id === 20),
                render: (value: boolean) => {
                    return value ? t("Yes") : t("No");
                },
            },

            actions: {
                title: t("Actions"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderActions,
            },
        };
        return col;
    }, [
        openAllocateJobModal,
        openAssignContractorModal,
        openEditFlagsModal,
        selectedCategories,
        t,
    ]);

    return (
        <>
            <Card title={t("Service dates")}>
                {loaded ? (
                    <Table
                        preferences="property-service-dates-table"
                        columns={columns}
                        {...properties}
                        alternateCsvFunction={handleDownloadClick}
                        hideChildComponent={toast.visible}
                    />
                ) : (
                    <Loading />
                )}
            </Card>

            {assignContractorsModalVisible && (
                <AssignContractorsModal
                    propertyIds={bulkPropertyIds}
                    complianceTypes={bulkPropertyCategories}
                    contractors={contractors}
                    hide={assignContractorsModalHide}
                />
            )}

            {editFlagsModalVisible && landlordId && (
                <Portal>
                    <Modal title={t("Edit flags")} hide={editFlagsModalHide}>
                        <ModalBody>
                            <PropertyFlagEditor
                                landlordId={landlordId}
                                flags={flags}
                                selectedFlagsIds={flagsIds}
                                onSave={editPropertyFlags}
                                onCancel={editFlagsModalHide}
                                updateValue={updateFlags}
                            />
                        </ModalBody>
                    </Modal>
                </Portal>
            )}

            {assignFlagsModalVisible && landlordId && (
                <Portal>
                    <Modal
                        title={t("Assign flags")}
                        hide={assignFlagsModalHide}
                    >
                        <ModalBody>
                            <PropertyFlagEditor
                                landlordId={landlordId}
                                flags={flags}
                                selectedFlagsIds={flagsIds}
                                onSave={assignPropertyFlags}
                                onCancel={assignFlagsModalHide}
                                updateValue={updateFlags}
                            />
                        </ModalBody>
                    </Modal>
                </Portal>
            )}

            {unableEditFlagsModalVisible && (
                <Portal>
                    <Modal
                        title={t("Can't edit flags")}
                        hide={unableEditFlagsModalHide}
                    >
                        <ModalBody>
                            <p>
                                {t(
                                    "You can't edit a flag while having more than one landlord selected",
                                )}
                            </p>
                        </ModalBody>
                    </Modal>
                </Portal>
            )}

            {allocateJobModalVisible && selectedProperty && (
                <AllocateJobSimpleModal
                    propertyId={selectedProperty.propertyId}
                    addressString={selectedProperty.addressString}
                    propertyCategoryId={selectedProperty.category.id}
                    propertyCategories={[selectedProperty.category]}
                    hide={allocateJobModalHide}
                />
            )}

            {toast.visible && (
                <Portal>
                    <Toast>{"Generating report. Check CSV Reports tab."}</Toast>
                </Portal>
            )}
        </>
    );
};

export default PropertyServiceDates;
