import { useContext, useEffect, useMemo } from "react";
import { filter, map } from "rxjs";
import { ISimpleCsvReport } from ".";
import { RealtimeContext } from "../../../components";
import config from "../../../config";
import { clearCache } from "../../cache";
import { addDays, getToday } from "../../dates";
import { realtimeUpdateList } from "../../realtime";
import filterLocal from "../filterLocal";
import useFilterable from "../useFilterable";

const useCsvReports = (
    onRecordsLoaded: (values: ISimpleCsvReport[], currentPage: number) => void,
) => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const startDate = useMemo(() => addDays(getToday(), -1).toISOString(), []);
    const endDate = useMemo(() => addDays(getToday(), 1).toISOString(), []);

    const filterable = useFilterable<ISimpleCsvReport>(
        `${config.miscApiUrl}/csvreports`,
        {
            params: {
                sortProperty: "date",
                sortDirection: "desc",
                filters: {
                    date: {
                        operator: "{AND}",
                        filters: [
                            { function: ">=", value: startDate },
                            { function: "<", value: endDate },
                        ],
                    },
                },
            },
        },
        onRecordsLoaded,
    );

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter(
                    (e) => e.event === "updated" && e.entity === "csv-report",
                ),
                map((e) => e.payload as ISimpleCsvReport),
            )
            .subscribe((value) => {
                const isVisible = filterLocal(filterable.filters, value);

                if (isVisible) {
                    const updatedList = realtimeUpdateList(
                        value,
                        filterable.records,
                    );

                    if (updatedList) {
                        filterable.updateValue(updatedList);
                    }
                }

                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realtimeObservable, filterable.records]);

    return filterable;
};

export default useCsvReports;
