import { has, mapValues, pickBy } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ITableProps } from ".";
import {
    Button,
    Checkbox,
    Grid,
    GridColumn,
    Select,
    UserPreferencesContext,
} from "..";
import { useSelectRecords } from "../../hooks";
import Loading from "../Loading";
import styles from "./Table.module.scss";
import TableFilters from "./TableFilters";
import TableFooter from "./TableFooter";
import TableHeader from "./TableHeader";
import TableHeaderCell from "./TableHeaderCell";
import TableRow from "./TableRow";

const Table = (props: ITableProps) => {
    const { t } = useTranslation();
    const userPreferencesContext = useContext(UserPreferencesContext);
    const [columns, setColumns] = useState<IColumn>(null as unknown as IColumn);
    const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
    const [bulkValue, setBulkValue] = useState("");
    const rowsIds = useMemo(
        () => props.records.map((row) => row.id),
        [props.records],
    );
    const selectRows = useSelectRecords(rowsIds, false);

    useEffect(() => {
        setBulkValue("");
    }, [props.records]);

    useEffect(() => {
        if (selectRows.selectedIds.length < 1) {
            setBulkValue("");
        }
    }, [selectRows.selectedIds]);

    useEffect(() => {
        const dictionary = mapValues(props.columns, (column) => ({
            visible: !column.hidden,
            title: column.title,
            canBeToggledByUser: column.canBeToggledByUser ?? true,
        }));

        const preferences: ITablePreferences =
            userPreferencesContext.preferences[props.preferences];

        if (preferences) {
            for (const column of Object.keys(preferences)) {
                if (has(dictionary, column)) {
                    dictionary[column].visible = preferences[column].visible;
                }
            }
        }

        setColumns(dictionary);
    }, [props.columns, props.preferences, userPreferencesContext.preferences]);

    const togglableColumns = useMemo(() => {
        if (columns) {
            return mapValues(
                pickBy(columns, (c) => c.canBeToggledByUser),
                (column) => ({
                    visible: column.visible,
                    title: column.title,
                }),
            );
        }

        return {};
    }, [columns]);

    useEffect(() => {
        if (columns) {
            setVisibleColumns(
                Object.keys(props.columns).filter(
                    (column) => columns[column].visible,
                ),
            );
        }
    }, [columns, props.columns]);

    const handleToggleColumn = (checked: boolean, column: string) => {
        const pref = { ...userPreferencesContext.preferences };
        const col = { ...columns };

        col[column].visible = checked;

        pref[props.preferences] = mapValues(col, (c) => ({
            visible: c.visible,
        }));

        userPreferencesContext.updatePreferences(pref).subscribe();

        setColumns(col);
    };

    const getActiveFilters = (property: string) => {
        const filterGroup = props.filters[property];
        return (filterGroup && filterGroup.filters) || [];
    };

    const updateBulkValue = (value: string) => setBulkValue(value);

    // TODO: Use Form with validation.
    const bulkApply = () => {
        if (props.bulkActions) {
            const bulkAction = props.bulkActions.find(
                (action) => action.value === bulkValue,
            );

            if (bulkAction) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                bulkAction.onSubmit(selectRows.selectedIds);
            }
        }
    };

    const rowSelectRender = (id: number) => {
        const isChecked = selectRows.selectedIds.some((i) => i === id);

        return (
            <Checkbox
                value={id}
                onChange={selectRows.handleRowSelect}
                checked={isChecked}
                useMargin={false}
                ariaLabel={t("Select row")}
            />
        );
    };

    return (
        <div>
            {!props.hideFilters && <TableFilters {...props} />}

            <div className={styles.gridWrapper}>
                <Grid>
                    <GridColumn size="half" cssRules={{ paddingLeft: "0" }}>
                        <div className={styles.bulkContainer}>
                            {props.actionButton &&
                                selectRows.selectedIds.length === 0 &&
                                props.actionButton}
                            {props.bulkActions &&
                                selectRows.selectedIds.length > 0 && (
                                    <Select
                                        options={props.bulkActions}
                                        onChange={updateBulkValue}
                                        value={bulkValue}
                                        allowEmpty={true}
                                        useMargin={false}
                                        cssRules={{
                                            display: "inline-block",
                                            width: "256px",
                                            marginRight: "10px",
                                        }}
                                        placeholder={t("Select a bulk action")}
                                    />
                                )}
                            {bulkValue && (
                                <Button onClick={bulkApply}>
                                    {t("Apply")}
                                </Button>
                            )}
                        </div>
                    </GridColumn>
                    <GridColumn size="half" cssRules={{ paddingRight: "0" }}>
                        <TableHeader
                            {...props}
                            columnsConfig={props.columns}
                            visibleColumns={visibleColumns}
                            columns={togglableColumns}
                            handleToggleColumn={handleToggleColumn}
                            alternateCsvFunction={props.alternateCsvFunction}
                            csvExportToastVisible={props.hideChildComponent}
                        />
                    </GridColumn>
                </Grid>
            </div>

            {props.loading ? (
                <Loading />
            ) : (
                <>
                    {visibleColumns.length > 0 && (
                        <div className={styles.tableScroll}>
                            <table className={styles.table}>
                                <thead>
                                    <tr className={styles.row}>
                                        {props.bulkActions && (
                                            <TableHeaderCell
                                                property="id"
                                                sortable={false}
                                                filterable={false}
                                                activeFilters={[]}
                                                {...props}
                                            >
                                                <Checkbox
                                                    onChange={
                                                        selectRows.handleAllRowsSelect
                                                    }
                                                    checked={
                                                        selectRows.allRowsChecked
                                                    }
                                                    useMargin={false}
                                                    ariaLabel={t(
                                                        "Select all rows",
                                                    )}
                                                />
                                            </TableHeaderCell>
                                        )}

                                        {visibleColumns.map((key) => {
                                            const column = props.columns[key];
                                            const property =
                                                column.field || key;

                                            return (
                                                <TableHeaderCell
                                                    key={key}
                                                    property={property}
                                                    sortable={column.sortable}
                                                    filterable={
                                                        column.filterable
                                                    }
                                                    type={column.type}
                                                    filterOperator={
                                                        column.filterOperator
                                                    }
                                                    activeFilters={getActiveFilters(
                                                        property,
                                                    )}
                                                    filterFormatter={
                                                        column.filterFormatter
                                                    }
                                                    getBooleanFilters={
                                                        column.getBooleanFilters
                                                    }
                                                    getColumnFilters={
                                                        column.getColumnFilters
                                                    }
                                                    filterAvailableFilters={
                                                        column.filterAvailableFilters
                                                    }
                                                    separator={column.separator}
                                                    title={column.title}
                                                    {...props}
                                                />
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.records.map((record) => (
                                        <TableRow
                                            key={record.id}
                                            record={record}
                                            visibleColumns={visibleColumns}
                                            rowSelectRender={rowSelectRender}
                                            isCheckboxColumnVisible={
                                                !!props.bulkActions
                                            }
                                            columns={props.columns}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                    <TableFooter {...props} />
                </>
            )}
        </div>
    );
};

interface ITablePreferences {
    [key: string]: {
        visible: boolean;
    };
}

interface IColumn {
    [key: string]: {
        visible: boolean;
        title: string;
        canBeToggledByUser: boolean;
    };
}

export default Table;
