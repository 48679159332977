import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { KeyValueWidget } from "../../../components";
import { useAwaitingAllocationJobCount } from "../../../utils/api/allocatedJobs";
import { encodeUrl } from "../../../utils/url";

const SlaJobsCompleted = ({
    priorities,
    startDate,
    endDate,
}: ISlaJobsCompletedProps) => {
    const { t } = useTranslation();
    const [jobStatus] = useState(["Completed"]);

    const { value: totalCompletedJobs, loaded: totalCompletedJobsLoaded } =
        useAwaitingAllocationJobCount({
            jobPriorities: priorities,
            startDate,
            endDate,
            uta: false,
            completedWithJob: true,
            jobStatus: jobStatus,
        });

    const {
        value: totalCompletedJobsWithinSLA,
        loaded: totalCompletedJobsWithinSLALoaded,
    } = useAwaitingAllocationJobCount({
        jobPriorities: priorities,
        startDate,
        endDate,
        uta: false,
        slaMet: true,
        completedWithJob: true,
        jobStatus: jobStatus,
    });

    const url = useMemo(
        () =>
            encodeUrl("/jobs/awaiting-allocation", {
                activeTab: ["completed"],
                uta: ["=false"],
                slaMet: ["=true"],
                completedWithJob: ["=true"],
                slaDate: [
                    `>=${startDate.toISOString()}{AND}<${endDate.toISOString()}`,
                ],
                ...(!priorities.includes("All") && {
                    jobPriority: [priorities.map((j) => `=${j}`).join("{OR}")],
                }),
            }),
        [endDate, priorities, startDate],
    );

    const jobsSummary = useMemo(() => {
        const percentage =
            totalCompletedJobs?.count > 0
                ? Math.round(
                      ((totalCompletedJobs?.count -
                          totalCompletedJobsWithinSLA?.count) /
                          totalCompletedJobs?.count) *
                          100,
                  )
                : 0;

        return [
            {
                key: t("Total jobs"),
                value: totalCompletedJobs?.count,
            },
            {
                key: t("Total jobs completed within SLA"),
                value: totalCompletedJobsWithinSLA?.count,
            },
            {
                key: t("Percentage of jobs that missed SLA"),
                value: `${percentage}%`,
            },
        ];
    }, [t, totalCompletedJobs, totalCompletedJobsWithinSLA]);

    return (
        <KeyValueWidget
            title={t("Jobs completed with SLA")}
            summary={jobsSummary}
            url={url}
            tooltipText={t(
                "Total allocated jobs linked to an awaiting allocation job, total completed with SLA, percentage of missed SLA jobs within the date filters selected.",
            )}
            loaded={
                totalCompletedJobsLoaded && totalCompletedJobsWithinSLALoaded
            }
        />
    );
};

interface ISlaJobsCompletedProps {
    priorities: string[];
    startDate: Date;
    endDate: Date;
}

export default SlaJobsCompleted;
