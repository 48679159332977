import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { from, map, Subject } from "rxjs";
import { webSocket } from "rxjs/webSocket";
import config from "../../config";
import { clearCache } from "../../utils/cache";
import RealtimeContext from "./RealtimeContext";

const RealtimeProvider = ({ children }: IRealtimeProviderProps) => {
    const [realtimeSubject] = useState(() => new Subject<IRealtimeEvent>());
    const [realtimeObservable] = useState(() => realtimeSubject.asObservable());
    const [connectSubject] = useState(() => new Subject());
    const [disconnectSubject] = useState(() => new Subject());
    const [subject] = useState(() =>
        webSocket<IRealtimeEvent | IWebSocketAuth>({
            url: config.websocketUrl,
            openObserver: connectSubject,
            closeObserver: disconnectSubject,
        }),
    );

    const { getAccessTokenSilently } = useAuth0();

    const connect = useCallback(() => {
        from(getAccessTokenSilently()).subscribe((token) => {
            subject.next({
                action: "authenticate",
                token: token,
            });
        });
    }, [getAccessTokenSilently, subject]);

    useEffect(() => {
        connectSubject.subscribe(() => connect());

        return () => {
            if (connectSubject) {
                connectSubject.unsubscribe();
            }
        };
    }, [connect, connectSubject]);

    useEffect(() => {
        disconnectSubject.subscribe(() => {
            clearCache();
            // TODO: Try to reconnect.
            // TODO: Display a popup with the option to refresh the page.
            // location.reload();
        });

        return () => {
            if (disconnectSubject) {
                disconnectSubject.unsubscribe();
            }
        };
    }, [disconnectSubject]);

    useEffect(() => {
        subject
            .pipe(map((e) => e as IRealtimeEvent))
            .subscribe((realtimeEvent) => realtimeSubject.next(realtimeEvent));

        // TODO: This code returns an ObjectUnsubscribedError: object unsubscribed.
        // return () => {
        //     if (subject) {
        //         subject.unsubscribe();
        //     }
        // };
    }, [realtimeSubject, subject]);

    useEffect(() => {
        const keepAlive = setInterval(() => {
            connect();
        }, 540000);

        return () => {
            clearInterval(keepAlive);
        };
    }, [connect]);

    return (
        <RealtimeContext.Provider value={{ realtimeObservable }}>
            {children}
        </RealtimeContext.Provider>
    );
};

interface IRealtimeProviderProps {
    children: ReactNode;
}

interface IWebSocketAuth {
    action: "authenticate";
    token: string;
}

export interface IRealtimeEvent {
    event: IEvent;
    entity: IEntity;
    payload: any;
}

type IEvent = "created" | "updated" | "deleted";

type IEntity =
    | "appliance"
    | "asset"
    | "property"
    | "job"
    | "job-comment"
    | "allocated-job"
    | "awaiting-allocation"
    | "unable-to-access"
    | "integration-failure"
    | "integration-job-approval"
    | "issue"
    | "issue-comment"
    | "engineer"
    | "answer"
    | "notification"
    | "tag"
    | "tenant"
    | "manual-upload-reset-approval"
    | "property-category"
    | "observations"
    | "property-note"
    | "csv-report";

export default RealtimeProvider;
