import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, InfiniteScroll } from "../../components";
import CsvReportTimeline, {
    ICsvReport,
} from "../../components/CsvReportTimeline";
import { ISimpleCsvReport, useCsvReports } from "../../utils/api/misc";

const CsvReports = () => {
    const { t } = useTranslation();

    const [csvReportLog, setCsvReportLog] = useState<ICsvReport[]>([]);

    const onRecordsLoaded = useCallback(
        (values: ISimpleCsvReport[], currentPage: number) => {
            const newRecords = currentPage === 1 ? [] : [...csvReportLog];

            newRecords.push(...values);
            setCsvReportLog(newRecords);
        },
        [csvReportLog],
    );

    const { loading, currentPage, totalPages, goToNextPage } =
        useCsvReports(onRecordsLoaded);

    const loadNextPage = () => {
        if (currentPage < totalPages) {
            goToNextPage();
        }
    };

    return (
        <Card title={t("Csv Reports")} bodyScroll={false}>
            <InfiniteScroll loading={loading} onScrollToBottom={loadNextPage}>
                {csvReportLog.length > 0 && (
                    <CsvReportTimeline csvReports={csvReportLog} />
                )}
            </InfiniteScroll>
        </Card>
    );
};

export default CsvReports;
