import { useContext, useMemo } from "react";
import { IRouteProps } from ".";
import { PropertyCategoriesContext } from "../../components";
import SideMenuContainer from "../../components/MainLayout/SideMenuContainer";
import { useHasPermission } from "../../hooks";
import AddManualUpload from "../../pages/AddManualUpload";
import AddUtaManualUpload from "../../pages/AddUtaManualUpload";
import AllocateJobs from "../../pages/AllocateJobs";
import AmendedDocuments from "../../pages/AmendedDocuments";
import { Appliance } from "../../pages/Appliance";
import Appliances from "../../pages/Appliances";
import AssetUnableToAccessList from "../../pages/AssetUnableToAccessList";
import AwaitingAllocation from "../../pages/AwaitingAllocation";
import Circuit from "../../pages/Circuit";
import Circuits from "../../pages/Circuits";
import ComplianceDashboard from "../../pages/ComplianceDashboard";
import ContractorAdd from "../../pages/ContractorAdd";
import Contractors from "../../pages/Contractors";
import CreateIssue from "../../pages/CreateIssue";
import CsvReports from "../../pages/CsvReports";
import DocumentStatus from "../../pages/DocumentStatus";
import Engineer from "../../pages/Engineer";
import EngineerAdd from "../../pages/EngineerAdd";
import Engineers from "../../pages/Engineers";
import EventLogs from "../../pages/EventLogs";
import { IntegrationApproval } from "../../pages/IntegrationApproval";
import Issue from "../../pages/Issue";
import Issues from "../../pages/Issues";
import Job from "../../pages/Job";
import JobLog from "../../pages/JobLog";
import JobLogRedirectPage from "../../pages/JobLogRedirectPage";
import JobReports from "../../pages/JobReports";
import Jobs from "../../pages/Jobs";
import KpiReport from "../../pages/KpiReport";
import LandlordPreferences from "../../pages/LandlordPreferences";
import LandlordRedirectPage from "../../pages/LandlordRedirectPage";
import LgsrAudit from "../../pages/LgsrAudit";
import ManualUploads from "../../pages/ManualUploads";
import Observation from "../../pages/Observation";
import Observations from "../../pages/Observations";
import Properties from "../../pages/Properties";
import PropertiesMap from "../../pages/PropertiesMap";
import Property from "../../pages/Property";
import PropertyAdd from "../../pages/PropertyAdd";
import PropertyServiceDates from "../../pages/PropertyServiceDates";
import QualifiedSupervisorAudits from "../../pages/QualifiedSupervisorAudits";
import Reconciliation from "../../pages/Reconciliation";
import ServiceDateResetApproval from "../../pages/ServiceDateResetApproval";
import UnableToAccess from "../../pages/UnableToAccess";
import UnableToAccessView from "../../pages/UnableToAccessView";
import UnregisterTag from "../../pages/UnregisterTag";
import UserManagement from "../../pages/UserManagement";

export const usePortalStandardRoutes = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);

    const canViewKpiReport = useHasPermission("kpi_reports");
    const canCreateProperties = useHasPermission("add_properties");
    const canViewElecTag = useHasPermission("electag");
    const canViewIntegrations = useHasPermission("integration_standard");
    const isPortalAdmin = useHasPermission("portal_admin");
    const isAdmin = useHasPermission("admin");

    const routes = useMemo(() => {
        const showElecTagRoutes =
            canViewElecTag &&
            (selectedCategories.length === 0 ||
                selectedCategories.some((c) => c.id === 20));

        const getCompliance = (): IRouteProps => {
            const children: IRouteProps[] = [
                {
                    title: "Dashboard",
                    index: true,
                    element: <ComplianceDashboard />,
                },
            ];

            if (canViewKpiReport) {
                children.push({
                    title: "KPI Report",
                    path: "kpi-report",
                    element: <KpiReport />,
                });
            }

            return {
                title: "Compliance",
                path: "/compliance",
                element: <SideMenuContainer appRoutes={children} />,
                children: children,
            };
        };

        const getManagement = (): IRouteProps => {
            const children: IRouteProps[] = [
                {
                    title: "Property Map",
                    index: true,
                    element: <PropertiesMap />,
                },
                {
                    title: "Properties",
                    path: "properties",
                    element: <Properties />,
                },
                {
                    path: "properties/:id",
                    element: <Property />,
                },
                {
                    title: "Service Dates",
                    path: "service-dates",
                    element: <PropertyServiceDates />,
                },
                {
                    title: "Appliances",
                    path: "appliances",
                    element: <Appliances />,
                },
                {
                    path: "appliances/:id",
                    element: <Appliance />,
                },
                {
                    title: "Contractors",
                    path: "contractors",
                    element: <Contractors />,
                },
                {
                    path: "contractors/add",
                    element: <ContractorAdd />,
                },
                {
                    title: "Engineers",
                    path: "engineers",
                    element: <Engineers />,
                },
                {
                    path: "engineers/add",
                    element: <EngineerAdd />,
                },
                {
                    path: "engineers/:id",
                    element: <Engineer />,
                },
            ];

            if (canCreateProperties) {
                children.push({
                    title: "Add Property",
                    path: "add-new-property",
                    element: <PropertyAdd />,
                });
            }

            if (showElecTagRoutes) {
                children.push(
                    {
                        title: "Circuits",
                        path: "circuits",
                        element: <Circuits />,
                    },
                    {
                        path: "circuits/:id",
                        element: <Circuit />,
                    },
                );
            }

            return {
                title: "Management",
                path: "/management",
                element: <SideMenuContainer appRoutes={children} />,
                children: children,
            };
        };

        const getJobs = (): IRouteProps => {
            const children: IRouteProps[] = [
                {
                    title: "Job Log",
                    index: true,
                    element: <JobLog />,
                },
                {
                    title: "Jobs",
                    path: "jobs",
                    element: <Jobs />,
                },
                {
                    path: "jobs/:id",
                    element: <Job />,
                },
                {
                    title: "Allocate Jobs",
                    path: "allocate-jobs",
                    element: <AllocateJobs />,
                },
                {
                    title: "Awaiting Allocation",
                    path: "awaiting-allocation",
                    element: <AwaitingAllocation />,
                },
                {
                    title: "Manual Uploads",
                    path: "manual-uploads",
                    element: <ManualUploads />,
                },
                {
                    path: "manual-uploads/create",
                    element: <AddManualUpload />,
                },
                {
                    path: "manual-uploads/create-uta",
                    element: <AddUtaManualUpload />,
                },
                {
                    title: "Reports",
                    path: "reports",
                    element: <JobReports />,
                },
                {
                    path: "job-log",
                    element: <JobLogRedirectPage />,
                },
            ];

            return {
                title: "Jobs",
                path: "/jobs",
                element: <SideMenuContainer appRoutes={children} />,
                children: children,
            };
        };

        const getIssues = (): IRouteProps => {
            const children: IRouteProps[] = [
                {
                    title: "Issues",
                    index: true,
                    element: <Issues />,
                },
                {
                    title: "Property UTA's",
                    path: "unabletoaccess",
                    element: <UnableToAccess />,
                },
                {
                    path: "unabletoaccess/:id",
                    element: <UnableToAccessView />,
                },
                {
                    title: "Asset UTA's",
                    path: "asset-uta",
                    element: <AssetUnableToAccessList />,
                },
                {
                    path: "asset-uta/:id",
                    element: <UnableToAccessView />,
                },
                {
                    path: "create-issue",
                    element: <CreateIssue />,
                },
                {
                    path: ":id",
                    element: <Issue />,
                },
            ];

            if (showElecTagRoutes) {
                children.push(
                    {
                        title: "Observations",
                        path: "observations",
                        element: <Observations />,
                    },
                    {
                        path: "observations/:id",
                        element: <Observation />,
                    },
                );
            }

            return {
                title: "Issues",
                path: "/issues",
                element: <SideMenuContainer appRoutes={children} />,
                children: children,
            };
        };

        const getDocuments = (): IRouteProps => {
            const children: IRouteProps[] = [
                {
                    title: "Document Status",
                    index: true,
                    element: <DocumentStatus />,
                },
                {
                    title: "Amended Documents",
                    path: "amended-documents",
                    element: <AmendedDocuments />,
                },
            ];

            return {
                title: "Documents",
                path: "/documents",
                element: <SideMenuContainer appRoutes={children} />,
                children: children,
            };
        };

        const getAudits = (): IRouteProps => {
            const children: IRouteProps[] = [
                {
                    title: "Audit",
                    index: true,
                    element: <LgsrAudit />,
                },
            ];

            if (showElecTagRoutes) {
                children.push({
                    title: "QS",
                    path: "qs",
                    element: <QualifiedSupervisorAudits />,
                });
            }

            return {
                title: "Audits",
                path: "/audits",
                element: <SideMenuContainer appRoutes={children} />,
                children: children,
            };
        };

        const getAdministration = (): IRouteProps => {
            const children: IRouteProps[] = [
                {
                    title: "Users",
                    index: true,
                    element: <UserManagement />,
                },
                {
                    title: "Preferences",
                    path: "preferences",
                    element: <LandlordPreferences />,
                },
                {
                    title: "Service Date Reset Approval",
                    path: "service-date-reset-approval",
                    element: <ServiceDateResetApproval />,
                },
                {
                    title: "Event Logs",
                    path: "event-logs",
                    element: <EventLogs />,
                },
            ];

            if (isAdmin) {
                children.push({
                    title: "Unregister Tag",
                    path: "unregister-tag",
                    element: <UnregisterTag />,
                });
            }

            if (canViewIntegrations) {
                children.push(
                    {
                        title: "Reconciliation",
                        path: "reconciliation",
                        element: <Reconciliation />,
                    },
                    {
                        title: "Integration Approval",
                        path: "integration-approval",
                        element: <IntegrationApproval />,
                    },
                );
            }

            return {
                title: "Administration",
                path: "/administration",
                element: <SideMenuContainer appRoutes={children} />,
                children: children,
            };
        };

        const getCsvReports = (): IRouteProps => {
            const children: IRouteProps[] = [
                {
                    title: "Downloads",
                    index: true,
                    element: <CsvReports />,
                },
            ];
            return {
                title: "CSV Reports",
                path: "/csv-reports",
                element: <SideMenuContainer appRoutes={children} />,
                children: children,
            };
        };

        const list: IRouteProps[] = [
            {
                path: "/",
                element: <LandlordRedirectPage />,
            },
            getCompliance(),
            getManagement(),
            getJobs(),
            getIssues(),
            getDocuments(),
            getAudits(),
            getCsvReports(),
        ];

        if (isPortalAdmin) {
            list.push(getAdministration());
        }

        return list;
    }, [
        canCreateProperties,
        canViewElecTag,
        canViewIntegrations,
        canViewKpiReport,
        isAdmin,
        isPortalAdmin,
        selectedCategories,
    ]);

    return routes;
};
