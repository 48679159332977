import { debounce } from "lodash";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { map } from "rxjs";
import { ITableHeaderProps, IVisibleColumn } from ".";
import { Button, Checkbox, Icon, InputField, Loading, Popover } from "../..";
import { useToggle } from "../../../hooks";
import { downloadCsv } from "../../../utils/csv";
import { getNow, toDateTimeString } from "../../../utils/dates";
import { formatValue, getValueFromObject } from "../../../utils/values";
import PopoverListItem from "../../Popover/PopoverListItem";
import styles from "./TableHeader.module.scss";

const TableHeader = ({
    applySearch,
    totalRecords,
    columns,
    handleToggleColumn,
    getAllRecords,
    visibleColumns,
    columnsConfig,
    alternateCsvFunction,
    canDownloadCsv = true,
    csvExportToastVisible = false,
}: ITableHeaderProps) => {
    const { t } = useTranslation();
    const { visible, hide, toggle } = useToggle();
    const popoverButton = useRef<HTMLButtonElement>(null);

    const { visible: handleColumnsVisible, toggle: handleColumnsToggle } =
        useToggle();

    const handleChange = useMemo(
        () =>
            debounce((value: string) => {
                applySearch(value);
            }, 1000),
        [applySearch],
    );

    const [csvDownloading, setCsvDownloading] = useState(false);

    const handleDownloadCsvClick = () => {
        if (alternateCsvFunction) {
            alternateCsvFunction(
                visibleColumns.map<IVisibleColumn>((vc) => {
                    return {
                        key: vc,
                        title: columnsConfig[vc].title,
                    };
                }),
            );
        } else {
            setCsvDownloading(true);

            getAllRecords()
                .pipe(
                    map((records) =>
                        records.map((record) =>
                            visibleColumns.map((column) => {
                                const columnConfig = columnsConfig[column];
                                const value = getValueFromObject(
                                    column,
                                    record,
                                );

                                if (columnConfig.render) {
                                    const output = columnConfig.render(
                                        value,
                                        record,
                                    );

                                    return typeof output === "object"
                                        ? value
                                        : output;
                                }

                                return formatValue(
                                    columnConfig.type || "string",
                                )(value);
                            }),
                        ),
                    ),
                )
                .subscribe({
                    next: (records) => {
                        const now = getNow();

                        downloadCsv(
                            `Report - ${toDateTimeString(now)
                                .replace("/", "-")
                                .replace(":", "_")}.csv`,
                            visibleColumns.map(
                                (column) => columnsConfig[column].title,
                            ),
                            records,
                        );

                        setCsvDownloading(false);
                    },
                    error: () => {
                        setCsvDownloading(false);
                    },
                });
        }
    };

    return (
        <div className={styles.container}>
            <InputField
                placeholder={t("Search")}
                onChange={handleChange}
                append={<Icon icon="search" ariaHidden={true} />}
                useMargin={false}
            />
            <div className={styles.totalResults}>
                <strong>{t("Total Results")}:</strong> {totalRecords}
            </div>
            <Button
                displayAsLink={true}
                onClick={toggle}
                setRef={popoverButton}
                size="small"
            >
                <Icon icon="more" ariaLabel={t("View more options")} />
            </Button>

            {visible && (
                <Popover
                    anchorElement={popoverButton}
                    csvExportToastVisible={csvExportToastVisible}
                    hide={hide}
                >
                    {canDownloadCsv && (
                        <PopoverListItem onClick={handleDownloadCsvClick}>
                            <div className={styles.action}>
                                {csvDownloading ? (
                                    <Loading small={true} smallWidth={24} />
                                ) : (
                                    <Icon
                                        icon="download"
                                        ariaHidden={true}
                                        display="inline-block"
                                    />
                                )}
                                <div className={styles.actionText}>
                                    {t("Download as CSV")}
                                </div>
                            </div>
                        </PopoverListItem>
                    )}

                    <PopoverListItem onClick={handleColumnsToggle}>
                        <div className={styles.action}>
                            <Icon
                                icon="gear"
                                display="inline-block"
                                ariaHidden={true}
                            />
                            <div className={styles.actionText}>
                                {t("Columns")}
                            </div>
                            <Icon
                                icon={
                                    handleColumnsVisible
                                        ? "chevron-up"
                                        : "chevron-down"
                                }
                                display="inline-block"
                                ariaLabel={t("Expand")}
                            />
                        </div>
                    </PopoverListItem>

                    {handleColumnsVisible && (
                        <div className={styles.columns}>
                            {Object.keys(columns).map((key) => (
                                <Checkbox
                                    key={key}
                                    value={key}
                                    checked={columns[key].visible}
                                    onChange={handleToggleColumn}
                                >
                                    {columns[key].title}
                                </Checkbox>
                            ))}
                        </div>
                    )}
                </Popover>
            )}
        </div>
    );
};

export default TableHeader;
