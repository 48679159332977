import { createPopper, Placement } from "@popperjs/core";
import {
    CSSProperties,
    ReactNode,
    RefObject,
    useEffect,
    useRef,
    useState,
} from "react";
import { Portal } from "..";
import { useCssClasses } from "../../hooks";
import useFocusTrap from "../../hooks/useFocusTrap";
import styles from "./Popover.module.scss";

const Popover = ({
    hide,
    anchorElement,
    children,
    width,
    maxWidth,
    placement = "bottom",
    applyPadding = true,
    enableFocusTrap = true,
    csvExportToastVisible = false,
}: IPopoverProps) => {
    const popoverElement = useRef<HTMLDivElement>(null);

    const { onKeyDown } = useFocusTrap(popoverElement, enableFocusTrap, hide);

    const [popoverStyles, setPopoverStyles] = useState<CSSProperties>({});

    useEffect(() => {
        if (
            anchorElement.current &&
            popoverElement.current &&
            !csvExportToastVisible
        ) {
            const popper = createPopper(
                anchorElement.current,
                popoverElement.current,
                {
                    placement,
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 3],
                            },
                        },
                        {
                            name: "preventOverflow",
                            options: {
                                padding: 20,
                            },
                        },
                        {
                            name: "applyStyles",
                            fn: (modifier) => {
                                setPopoverStyles({
                                    position: modifier.state.styles.popper
                                        .position as any,
                                    transform:
                                        modifier.state.styles.popper.transform,
                                    top: modifier.state.styles.popper.top,
                                    right: modifier.state.styles.popper.right,
                                    bottom: modifier.state.styles.popper.bottom,
                                    left: modifier.state.styles.popper.left,
                                });
                            },
                        },
                    ],
                },
            );

            setTimeout(() => {
                popper.forceUpdate();
            }, 0);

            return () => popper.destroy();
        } else {
            setPopoverStyles({});
        }
    }, [anchorElement, popoverElement, placement, csvExportToastVisible]);

    useEffect(() => {
        const handleClickout = (event: MouseEvent) => {
            if (
                anchorElement.current === null ||
                (anchorElement.current &&
                    !anchorElement.current.contains(event.target as Node) &&
                    popoverElement.current &&
                    !popoverElement.current.contains(event.target as Node))
            ) {
                hide();
            }
        };

        document.addEventListener("click", handleClickout);
        return () => document.removeEventListener("click", handleClickout);
    }, [anchorElement, popoverElement, hide]);

    const containerCssClasses = useCssClasses(
        styles.container,
        applyPadding ? styles.padding : "",
    );

    return (
        <Portal>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <div
                onKeyDown={onKeyDown}
                role="dialog"
                className={containerCssClasses}
                ref={popoverElement}
                style={{ ...popoverStyles, width, maxWidth }}
                data-test-id="Popover_Div"
            >
                {children}
            </div>
        </Portal>
    );
};

interface IPopoverProps {
    hide: () => void;
    anchorElement: RefObject<HTMLElement>;
    children: ReactNode;
    width?: number;
    maxWidth?: number;
    applyPadding?: boolean;
    placement?: Placement;
    enableFocusTrap?: boolean;
    //TODO: Make this non nullable when all the components are updated
    csvExportToastVisible?: boolean;
}

export default Popover;
