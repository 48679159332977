import { useCallback, useContext, useEffect } from "react";
import { IPropertiesGraph } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { clearCache } from "../../cache";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const usePropertiesStatusGraph = (
    occupied: string,
    capped: string,
    jobAllocatedStatus: string,
    electricSupplyOn: string,
    propertyVisited: string,
    flags: string[],
) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, send, loaded, loading } = useApiValue<IPropertiesGraph>({});
    const sendRequest = useXTagApiRequest();

    const refresh = useCallback(
        (shouldClearCache = true) => {
            if (shouldClearCache) {
                clearCache();
            }

            return send(
                sendRequest({
                    url: `${config.propertiesApiUrl}/graph/status`,
                    method: "GET",
                    urlParams: {
                        ...(occupied !== "" && {
                            occupiedStatus: [
                                `=${occupied === "true" ? "Occupied" : "Void"}`,
                            ],
                        }),
                        ...(capped !== "" && {
                            cappedStatus: [
                                `=${
                                    capped === "true" ? "Capped" : "Not Capped"
                                }`,
                            ],
                        }),
                        ...(electricSupplyOn !== "" && {
                            electricSupplyOn: [`=${electricSupplyOn}`],
                        }),
                        ...(propertyVisited !== "" && {
                            propertyVisited: [`=${propertyVisited}`],
                        }),
                        ...(flags.length > 0 && {
                            flags: [
                                `${flags.map((f) => `=${f}`).join("{OR}")}`,
                            ],
                        }),
                        serviceAllocatedStatus: [jobAllocatedStatus],
                        ...getGlobalUrlParameters(
                            selectedCategories.map((c) => c.id),
                            activeUserParentsIds,
                        ),
                    },
                }),
            );
        },
        [
            send,
            sendRequest,
            occupied,
            capped,
            electricSupplyOn,
            propertyVisited,
            flags,
            jobAllocatedStatus,
            selectedCategories,
            activeUserParentsIds,
        ],
    );

    useEffect(() => {
        const subscription = refresh(false).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [refresh]);

    return { value, refresh, loaded, loading };
};

export default usePropertiesStatusGraph;
