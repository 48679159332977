import CsvReportTimeline from "./CsvReportTimeline";

export interface ICsvReport {
    id: number;
    filePath: string;
    reportStatus: string;
    reportType: string;
    date: string;
}

export interface ICsvReportTimelineProps {
    csvReports: ICsvReport[];
}

export default CsvReportTimeline;
